import { createNewChallenge } from '../models/challenge.factory';
import { ChallengeType } from '../models/challenge.types';

import { listOfMockChallenges as defaultChallenges } from '../mocks/mockChallenges';

// BASIC LOCAL STORAGE FUNCTIONS

export const getDataLocalStorage = <T>(storeName: string): Array<T> | null => {
  const result = localStorage.getItem(storeName);
  if (!result) return null;
  return JSON.parse(result);
};

export const persistDataLocalStorage = <T>(
  storeName: string,
  data: Array<T>
): void => {
  localStorage.setItem(storeName, JSON.stringify(data));
};

export const removeDataLocalStorage = (storeName: string): void => {
  localStorage.removeItem(storeName);
};

// CHALLENGES LOCAL STORAGE FUNCTIONS

// saved-in-local-storage is a challenge that helps to check if the user has already used the app
const defaultChallengesList = ['1', 'saved-in-local-storage'];

export const intitLocalStorage = (defaultChallenges: Array<ChallengeType>) => {
  const totalOfChallenges = getDataLocalStorage('challenges');

  defaultChallenges.forEach((challenge) => {
    if (totalOfChallenges === null) return;

    if (totalOfChallenges.includes(challenge.id)) {
      persistDataLocalStorage(
        `challenge-${challenge.id}`,
        challenge as unknown as Array<unknown>
      );
    }
  });
};
export const getTotalsChallengesLocalStorage = () => {
  const totalChallenges = getDataLocalStorage('challenges') as Array<string>;

  if (totalChallenges) return totalChallenges;
  return [];
};
export const updateTotalChallengesLocalStorage = (challengeId: string) => {
  const totalChallenges = getDataLocalStorage('challenges');

  totalChallenges?.forEach((challenge, index) => {
    if (challenge === challengeId) {
      totalChallenges.splice(index, 1);
    }
  });
  if (totalChallenges?.length === 0) removeDataLocalStorage('challenges');
  else {
    persistDataLocalStorage(
      'challenges',
      totalChallenges as unknown as Array<unknown>
    );
  }
};

export const AddDefaultChallengesLocalStorage = () => {
  persistDataLocalStorage('challenges', defaultChallengesList);
  console.log('Adding default challenges to local storage...');

  intitLocalStorage(defaultChallenges);
};

export const AddNewChallengeLocalStorage = (
  title: string,
  description: string,
  length: number
) => {
  const totalChallenges = getTotalsChallengesLocalStorage();

  const newChallenge = createNewChallenge(title, description, length);

  persistDataLocalStorage(
    `challenge-${newChallenge.id}`,
    newChallenge as unknown as Array<unknown>
  );

  if (totalChallenges) {
    persistDataLocalStorage('challenges', [
      ...totalChallenges,
      newChallenge.id,
    ] as unknown as Array<unknown>);
  } else {
    persistDataLocalStorage('challenges', [
      newChallenge.id,
    ] as unknown as Array<unknown>);
  }
};

export const getChallengeInfoLocalStorage = (challengeId: string) => {
  const challenge = getDataLocalStorage(`challenge-${challengeId}`);

  if (challenge) return challenge as unknown as ChallengeType;
  return null;
};

export const updateChallengeInfoLocalStorage = (challenge: ChallengeType) => {
  persistDataLocalStorage(
    `challenge-${challenge.id}`,
    challenge as unknown as Array<unknown>
  );
};
