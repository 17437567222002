import './header.scss';

export const Header = () => {
  return (
    <div className='app-header'>
      <div className='app-header__container'>
        <h1 className='app-header__title'>
          100days<span>.top</span>
        </h1>
        <nav className='app-header__nav'>
          <a href='#about' className='app-header__nav-item'>
            About
          </a>
          <a href='#faq' className='app-header__nav-item'>
            Faq
          </a>
        </nav>
      </div>
    </div>
  );
};
