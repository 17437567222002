import { useEffect, useState } from 'react';
import { AboutUs } from '../../components/about.us/about.us';
import { ButtonWithModal } from '../../components/button.with.modal/button.with.modal';
import { Challenge } from '../../components/challenge/challenge';
import { Faq } from '../../components/faq/faq';
import {
  AddDefaultChallengesLocalStorage,
  getTotalsChallengesLocalStorage,
} from '../../services/local.storage';
import './home.page.scss';

export const HomePage = () => {
  const [challenges, setChallenges] = useState([] as string[] | []);

  useEffect(() => {
    const totalChallenges = getTotalsChallengesLocalStorage();

    // If there are no challenges in local storage, add the default ones
    if (totalChallenges.length === 0) {
      AddDefaultChallengesLocalStorage();
      // Now we can get the challenges from local storage and set them to the state
      setChallenges(getTotalsChallengesLocalStorage());
    } else {
      setChallenges(totalChallenges);
    }
  }, []);

  return (
    <div className='home-page'>
      <section className='home-page__challenges'>
        {challenges &&
          challenges.map((challengeId) => {
            return <Challenge key={challengeId} challengeId={challengeId} />;
          })}
        <div className='home-page__add-challenge'>
          <ButtonWithModal
            updateStateActionInModal={setChallenges}
            buttonText='Add new challenge'
            buttonSize='large'
            type='add'
          />
        </div>
      </section>

      <div className='home-page__wrapper'>
        <AboutUs></AboutUs>
        <Faq></Faq>
      </div>
    </div>
  );
};
