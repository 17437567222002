import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useState } from 'react';
import { ChallengeType } from '../../models/challenge.types';
import {
  getDataLocalStorage,
  persistDataLocalStorage,
  removeDataLocalStorage,
  updateTotalChallengesLocalStorage,
} from '../../services/local.storage';
import { getCurrentDayFormatted } from '../../utils/dates';
import { ButtonWithModal } from '../button.with.modal/button.with.modal';
import { Button } from '../button/button';
import { DayBox } from '../day/day.box';
import './challenge.scss';

export const Challenge = ({
  challengeId,
}: {
  challengeId: ChallengeType['id'];
}) => {
  const dataChallenge = getDataLocalStorage(
    `challenge-${challengeId}`
  ) as unknown as ChallengeType;

  const [challenge, setChallenge] = useState(dataChallenge);
  const [showTodayCompleted, setShowTodayCompleted] = useState(true);

  const lastCompletedDay = challenge?.days.findIndex((day) => !day.isCompleted);
  const currentDayFormatted = getCurrentDayFormatted();

  const handleDayClick = (dayIndex: number) => {
    const updatedDays = challenge.days.map((day, index) => {
      if (index === dayIndex) {
        return { ...day, isCompleted: !day.isCompleted };
      }
      return day;
    });

    let updatedChallenge = { ...challenge, days: updatedDays };

    // Update lastDayCompleted
    if (dayIndex === lastCompletedDay) {
      setShowTodayCompleted(true);
      updatedChallenge = {
        ...updatedChallenge,
        lastDayCompleted: currentDayFormatted,
      };
    } else {
      setShowTodayCompleted(false);
      updatedChallenge = {
        ...updatedChallenge,
        lastDayCompleted: '',
      };
    }

    // Check if challenge is completed

    const isChallengeCompleted = updatedChallenge.days.every(
      (day) => day.isCompleted
    );

    updatedChallenge = {
      ...updatedChallenge,
      isChallengeCompleted,
    };

    // Update State and Local Storage
    setChallenge(updatedChallenge);
    persistDataLocalStorage(
      `challenge-${challengeId}`,
      updatedChallenge as unknown as Array<unknown>
    );
  };

  // ClassName for the days-container

  // This could be improved

  const daysContainerClassName = () => {
    if (challenge.isChallengeCompleted && challenge.days.length <= 10) {
      return 'days-container completed small-length';
    } else if (challenge.isChallengeCompleted) {
      return 'days-container completed';
    } else if (challenge.days.length <= 10) {
      return 'days-container small-length';
    }
    return 'days-container';
  };

  // Button Handlers

  const handleResetChallenge = () => {
    const updatedDays = challenge.days.map((day) => {
      return { ...day, isCompleted: false };
    });

    const updatedChallenge = {
      ...challenge,
      days: updatedDays,
      isChallengeCompleted: false,
      lastDayCompleted: '',
    };

    setChallenge(updatedChallenge);
    persistDataLocalStorage(
      `challenge-${challengeId}`,
      updatedChallenge as unknown as Array<unknown>
    );
  };

  const handleDeleteChallenge = () => {
    removeDataLocalStorage(`challenge-${challengeId}`);
    updateTotalChallengesLocalStorage(challengeId);
    setChallenge(null as unknown as ChallengeType);

    // This could be improved but it works for now
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    AOS.init();
  }, []);

  console.log('Render Challenge', challenge?.days.length);

  return (
    <>
      {challenge && (
        <div className='challenge-container'>
          <div className='challenge-block' data-aos={'flip-left'}>
            <div className='challenge-block__top'>
              {challenge.isChallengeCompleted ? (
                <div className='challenge-block__title--completed'> </div>
              ) : (
                <h2 className='challenge-block__title'>{challenge.title}</h2>
              )}

              {showTodayCompleted &&
              challenge.lastDayCompleted === currentDayFormatted ? (
                <p className='challenge-block__today-completed'></p>
              ) : (
                <p className='challenge-block__today-todo'></p>
              )}
            </div>
            {challenge.isChallengeCompleted ? (
              <p className='challenge-block__description--completed'>
                {challenge.title}
              </p>
            ) : (
              <p className='challenge-block__description'>
                {challenge.description}
              </p>
            )}

            <div className={daysContainerClassName()}>
              {challenge.days.map((day, index) => {
                return (
                  <DayBox
                    day={day}
                    dayIndex={index}
                    handleDayClick={handleDayClick}
                    lastCompletedDay={lastCompletedDay}
                    isChallengeCompleted={challenge.isChallengeCompleted}
                    challengeLength={challenge.days.length}
                    key={index}
                  />
                );
              })}
            </div>
            <div
              className={
                challenge.isChallengeCompleted
                  ? 'challenge-block__buttons-container  challenge-block__buttons-container--completed'
                  : 'challenge-block__buttons-container'
              }
            >
              {challenge.isChallengeCompleted && (
                <Button
                  text='Retry'
                  type='reset'
                  onClick={handleResetChallenge}
                />
              )}
              {!challenge.isChallengeCompleted && (
                <ButtonWithModal
                  updateStateActionInModal={setChallenge}
                  buttonText='Edit'
                  type='edit'
                  challengeId={challengeId}
                />
              )}
              <Button
                text='Delete'
                type='delete'
                onClick={handleDeleteChallenge}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
