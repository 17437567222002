import { useEffect, useRef, useState } from 'react';
import {
  getChallengeInfoLocalStorage,
  updateChallengeInfoLocalStorage,
} from '../../services/local.storage';
import './modal.edit.scss';
import { ChallengeType } from '../../models/challenge.types';

export const ModalEdit = ({
  setShowModal,
  updateStateActionInModal,
  challengeId,
}: {
  setShowModal: (value: boolean) => void;
  updateStateActionInModal: (value: any) => void;
  challengeId: string | undefined;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const challengeInfo = challengeId
    ? getChallengeInfoLocalStorage(challengeId)
    : null;

  const [formData, setFormData] = useState({
    challengeName: challengeInfo?.title || '',
    challengeDescription: challengeInfo?.description || '',
  } as any);

  const handleChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const updatedChallenge = {
      ...challengeInfo,
      title: formData.challengeName,
      description: formData.challengeDescription,
    } as ChallengeType;

    updateChallengeInfoLocalStorage(updatedChallenge);
    updateStateActionInModal(updatedChallenge);

    setShowModal(false);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <>
      <div className='modal-edit'>
        <div className='modal-edit__content'>
          <h2 className='modal-edit__title'> Edit challenge</h2>

          <form
            id={`challenge-edit-form-${challengeId}`}
            onSubmit={handleSubmit}
          >
            <label className='modal-edit__label' htmlFor='challenge-edit-name'>
              New Name
            </label>
            <input
              className='modal-edit__input'
              type='text'
              id='challenge-edit-name'
              placeholder='I want to achieve...'
              name='challengeName'
              value={formData.challengeName}
              onChange={handleChange}
              maxLength={20}
              ref={inputRef}
              required
            />

            <label
              className='modal-edit__label'
              htmlFor='challenge-edit-description'
            >
              New Description
            </label>

            <input
              className='modal-edit__input'
              type='text'
              id='challenge-edit-description'
              placeholder='And i will...'
              name='challengeDescription'
              value={formData.challengeDescription}
              onChange={handleChange}
              maxLength={60}
              required
            />

            <button
              type='submit'
              form={`challenge-edit-form-${challengeId}`}
              className='modal-edit__button'
            >
              SAVE
            </button>
          </form>

          <button
            className='modal-edit__close-button'
            onClick={() => setShowModal(false)}
          >
            x
          </button>
        </div>
      </div>
    </>
  );
};
