import { ChallengeType } from '../models/challenge.types';

export const demoChallengeWithFirstDayCompleted: ChallengeType = {
  id: '1',
  title: 'Demo Challenge',
  description: 'Edit this challenge to start o create a new one',
  lastDayCompleted: '',
  isChallengeCompleted: false,
  days: [
    { isCompleted: true }, // 1st day set to true
    ...Array.from({ length: 99 }, (_, index) => ({
      isCompleted: false,
    })),
  ],
};

// MOCKS
export const mockChallengeWith99DaysCompleted: ChallengeType = {
  id: '2',
  title: 'Demo Challenge',
  description: 'Edit this challenge to start o create a new one',
  lastDayCompleted: '',
  isChallengeCompleted: false,
  days: [
    ...Array.from({ length: 99 }, (_, index) => ({
      isCompleted: true,
    })),
    { isCompleted: false }, // 100th day set to false
  ],
};

export const mockChallenge2: ChallengeType = {
  id: '2',
  title: 'Loose weight',
  description: 'Go 100 days without eating sugar',
  lastDayCompleted: '',
  isChallengeCompleted: false,
  days: [
    ...Array.from({ length: 99 }, (_, index) => ({
      isCompleted: true,
    })),
    { isCompleted: false }, // 100th day set to false
  ],
};

export const listOfMockChallenges = [demoChallengeWithFirstDayCompleted];
