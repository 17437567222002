import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import './faq.scss';

export const Faq = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section id='faq' className='faq' data-aos={'fade-up'}>
      <h2 className='faq__title'>How to use?</h2>
      <Accordion m={0}>
        <AccordionItem>
          <h3>
            <AccordionButton
              _expanded={{ bg: '#7768ae', color: 'white', fontWeight: 'bold' }}
            >
              <Box
                as='span'
                flex='1'
                textAlign='left'
                fontSize='xl'
                fontWeight={'bold'}
              >
                How do I get started?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h3>
          <AccordionPanel
            pb={4}
            pt={4}
            bg={'white'}
            maxWidth={1000}
            fontSize={'lg'}
          >
            You can start by editing the demo challenge or deleting it and
            creating your own. Add your goal and description, and your 100-day
            challenge will be automatically created.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h3>
            <AccordionButton
              _expanded={{ bg: '#7768ae', color: 'white', fontWeight: 'bold' }}
            >
              <Box
                as='span'
                flex='1'
                textAlign='left'
                fontSize='xl'
                fontWeight={'bold'}
              >
                Do I need to register?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h3>
          <AccordionPanel
            pb={4}
            pt={4}
            bg={'white'}
            maxWidth={1000}
            fontSize={'lg'}
          >
            No, you don't need to register to use 100days.top.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h3>
            <AccordionButton
              _expanded={{ bg: '#7768ae', color: 'white', fontWeight: 'bold' }}
            >
              <Box
                as='span'
                flex='1'
                textAlign='left'
                fontSize='xl'
                fontWeight={'bold'}
              >
                Are my challenges public?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h3>
          <AccordionPanel
            pb={4}
            pt={4}
            bg={'white'}
            maxWidth={1000}
            fontSize={'lg'}
          >
            No, your challenges are securely saved on your device and will stay
            there. Just a heads up, any updates made on your mobile won't sync
            with your computer.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h3>
            <AccordionButton
              _expanded={{ bg: '#7768ae', color: 'white', fontWeight: 'bold' }}
            >
              <Box
                as='span'
                flex='1'
                textAlign='left'
                fontSize='xl'
                fontWeight={'bold'}
              >
                How many challenges can I add?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h3>
          <AccordionPanel
            pb={4}
            pt={4}
            bg={'white'}
            maxWidth={1000}
            fontSize={'lg'}
          >
            You can add as many challenges as you'd like to achieve. However,
            considering it's a 100-day journey, it's better to focus on
            completing one rather than leaving several unfinished. It's not
            recommended to have more than 3 challenges at a time.
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </section>
  );
};
