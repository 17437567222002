import { Footer } from '../footer/footer';
import { Header } from '../header/header';
import { Analytics } from '@vercel/analytics/react';
import './layout.scss';
export function Layout({ children }: { children: React.JSX.Element }) {
  return (
    <div className='layout-container'>
      <Header></Header>
      <section className='layout-children'>{children}</section>
      <Analytics />

      <Footer></Footer>
    </div>
  );
}
