import React, { useState } from 'react';
import { ChallengeType } from '../../models/challenge.types';
import { Button } from '../button/button';
import { ModalAdd } from '../modal.add/modal.add';
import './button.with.modal.scss';
import { ModalEdit } from '../modal.edit/modal.edit';

export const ButtonWithModal = ({
  updateStateActionInModal,
  challengeId,
  type,
  buttonText,
  buttonSize,
}: {
  updateStateActionInModal: (value: any) => void;
  challengeId?: ChallengeType['id'];
  type: string;
  buttonText: string;
  buttonSize?: string;
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        text={buttonText}
        size={buttonSize}
        type={type}
        onClick={() => setShowModal(true)}
      />

      {showModal && type === 'add' && (
        <ModalAdd
          setShowModal={setShowModal}
          updateStateActionInModal={updateStateActionInModal}
        />
      )}
      {showModal && type === 'edit' && (
        <ModalEdit
          setShowModal={setShowModal}
          updateStateActionInModal={updateStateActionInModal}
          challengeId={challengeId}
        />
      )}
    </>
  );
};
