import { ChallengeType } from './challenge.types';
import { generateId } from '../utils/generate.id';

export const createNewChallenge = (
  title: string,
  description: string,
  length: number
): ChallengeType => {
  const days = Array.from({ length: length }, (_, index) => ({
    isCompleted: false,
  }));

  const newChallenge: ChallengeType = {
    id: generateId(),
    title,
    description,
    lastDayCompleted: '',
    days,
    isChallengeCompleted: days.every((day) => day.isCompleted),
  };

  return newChallenge;
};
