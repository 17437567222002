import AOS from 'aos';
import 'aos/dist/aos.css';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import './about.us.scss';
import barsImage from '../../assets/bars.png';

export const AboutUs = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className='about-section' id='about' data-aos='fade-up'>
      <h2 className='about-section__title'>What is 100days.top?</h2>
      <div className='about-section__image-wrapper' data-aos='fade-up'>
        <img src={barsImage} alt='bars' className='about-section__image' />
      </div>

      <p>
        100days.top is a platform designed to assist you in reaching your goals.
      </p>

      <p>But... how?</p>

      <p>
        Have you ever heard of the <span> 100-day technique?</span> Let me
        explain it to you.
      </p>

      <p>
        Achieving results in the short term might seem impossible, but what if
        you committed to a total of 100 days dedicated to a habit or a task?
      </p>
      <p>
        <span>That's when the magic happens.</span>
      </p>

      <p>
        Alright... 100 days might appear to be a high number, which is why here,
        you can mark off each passing day as you progress, allowing you to{' '}
        <span>visualize it and stay motivated</span>. You may not achieve the
        exact goal you were expecting, but something remarkable is bound to
        occur.
      </p>
    </section>
  );
};
