import { HomePage } from '../../pages/home/home.page';
import { Layout } from '../layout/layout';

function App() {
  return (
    <div className='App'>
      <Layout>
        <HomePage></HomePage>
      </Layout>
    </div>
  );
}

export default App;
