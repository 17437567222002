import { useEffect, useRef, useState } from 'react';
import {
  AddNewChallengeLocalStorage,
  getTotalsChallengesLocalStorage,
} from '../../services/local.storage';
import './modal.add.scss';

export const ModalAdd = ({
  setShowModal,
  updateStateActionInModal,
}: {
  setShowModal: (value: boolean) => void;
  updateStateActionInModal: (value: any) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [formData, setFormData] = useState({
    challengeName: '',
    challengeDescription: '',
    challengeLength: 100,
  } as any);

  const handleChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    //Convert the challengeLength to a number
    formData.challengeLength = parseInt(formData.challengeLength);

    AddNewChallengeLocalStorage(
      formData.challengeName,
      formData.challengeDescription,
      formData.challengeLength
    );

    const challenges = getTotalsChallengesLocalStorage();
    updateStateActionInModal(challenges);

    setShowModal(false);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <>
      <div className='modal-add'>
        <div className='modal-add__content'>
          <h2 className='modal-add__title'> Add a challenge</h2>

          <form id='challenge-add-form' onSubmit={handleSubmit}>
            <label className='modal-add__label' htmlFor='challenge-add-name'>
              Challenge Name
            </label>
            <input
              className='modal-add__input'
              type='text'
              id='challenge-add-name'
              placeholder='I want to achieve...'
              name='challengeName'
              value={formData.challengeName}
              onChange={handleChange}
              ref={inputRef}
              required
            />

            <label
              className='modal-add__label'
              htmlFor='challenge-add-description'
            >
              Challenge Description
            </label>

            <input
              className='modal-add__input'
              type='text'
              id='challenge-add-description'
              placeholder='And i will...'
              name='challengeDescription'
              value={formData.challengeDescription}
              onChange={handleChange}
              required
            />

            <label className='modal-add__label' htmlFor='challenge-add-length'>
              Total Days
            </label>
            <select
              className='modal-add__input'
              id='challenge-add-length'
              value={formData.challengeLength}
              name='challengeLength'
              onChange={handleChange}
            >
              <option value='100'>100</option>
              <option value='30'>30</option>
              <option value='10'>10</option>
            </select>

            <button
              type='submit'
              form='challenge-add-form'
              className='modal-add__button'
            >
              Start
            </button>
          </form>

          <button
            className='modal-add__close-button'
            onClick={() => setShowModal(false)}
          >
            x
          </button>
        </div>
      </div>
    </>
  );
};
