import { ChallengeDayType } from '../../models/challenge.types';
import './day.box.scss';

export const DayBox = ({
  day,
  dayIndex,
  handleDayClick,
  lastCompletedDay,
  isChallengeCompleted,
  challengeLength,
}: {
  day: ChallengeDayType;
  dayIndex: number;
  handleDayClick: (dayIndex: number) => void;
  lastCompletedDay: number;
  isChallengeCompleted: boolean;
  challengeLength: number;
}) => {
  const isCompleted = day.isCompleted;
  const isNext = dayIndex === lastCompletedDay;
  const isCurrent = dayIndex === lastCompletedDay - 1;
  const isLast = dayIndex === challengeLength - 1;

  const canbeClicked = isNext || isCurrent || (isLast && isChallengeCompleted);

  // CONSOLE LOGS
  // console.log(dayIndex);
  // isLast && console.log('isLast');

  function getDayClassName() {
    const isCompletedClass = isCompleted ? 'completed' : '';
    const isNextClass = isNext ? 'next' : '';
    const isCurrentClass =
      isCurrent || (isLast && isChallengeCompleted) ? 'current' : '';

    return `day-box ${isCompletedClass} ${isNextClass} ${isCurrentClass}`;
  }

  return (
    <div
      className={getDayClassName()}
      onClick={() => canbeClicked && handleDayClick(dayIndex)}
    >
      <p>{dayIndex + 1}</p>
    </div>
  );
};
